import { template as template_f30c6423a35143429b9709e090ec9140 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_f30c6423a35143429b9709e090ec9140(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
