import { template as template_07d307e868c2446fbccae65e76e41ac9 } from "@ember/template-compiler";
const WelcomeHeader = template_07d307e868c2446fbccae65e76e41ac9(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
