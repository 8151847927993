import { template as template_61c9e9d9689c4f1ba35ca8ad57963514 } from "@ember/template-compiler";
const FKControlMenuContainer = template_61c9e9d9689c4f1ba35ca8ad57963514(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
